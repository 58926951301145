@import 'variables'; @import 'variables';
.Dot {
  $size: 14px;

  background: purple;
  border-radius: 50%;
  height: $size;
  margin: ($lineThickness - $size) / 2;
  width: $size;
  z-index: 5;
}
